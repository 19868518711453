//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import kebabCase from 'lodash.kebabcase'
import blok from '~/mixins/blok'
export default {
  mixins: [blok],
  computed: {
    gridClass () {
      const cls = [
        'tw-grid',
        'tw-grid-cols-3',
        this.blok.size !== 'sm' ? 'lg:tw-grid-cols-6' : '',
        this.blok.size === 'sm' ? 'tw-gap-4' : 'tw-gap-8',
        'tw-items-center',
      ]

      return cls
    },
    logos () {
      return (this.blok.logos || []).map((logo) => {
        return {
          link: this.$url.makeRoot(logo.link.cached_url),
          logo: this.$sb.parseImage(logo.logo),
          logo_width: logo.logo_width,
          logo_height: logo.logo_height
        }
      }).filter(l => l.logo)
    }
  },
  methods: {
    appendedComponentName (pascalCaseName) {
      return this.getBlokComponentName(kebabCase(pascalCaseName))
    }
  }
}
